<template>
  <main>
    <trac-loading v-if="loading"></trac-loading>

    <div v-else>
      <h1 class="mt-10 text-lg font-bold">Reporting Page</h1>
      <form @submit.prevent="submit">
        <div class="grid grid-cols-3 gap-10 mt-10">
          <div v-for="(data, index) in loanData" :key="index">
            <div class="mb-3 font-medium">{{ data.name }}</div>
            <div v-if="data.type === 'number' && data.payload === 'bvn'">
              <input
                type="number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                class="py-3 text-sm leading-8 w-full text-gray-900 font-medium placeholder:text-gray-three pl-10 pr-4 block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                v-model="form[data.payload]"
                maxlength="11"
              />
            </div>
            <div
              v-if="
                data.payload !== 'bvn' &&
                  (data.type === 'text' ||
                    data.type === 'number' ||
                    data.type === 'date' ||
                    data.type === 'email')
              "
            >
              <input
                :required="data.required"
                :type="data.type"
                class="py-3 text-sm leading-8 w-full text-gray-900 font-medium placeholder:text-gray-three pl-10 pr-4 block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                v-model="form[data.payload]"
              />
            </div>
            <div v-if="data.type === 'select'">
              <select
                class="py-5 px-6 leading-8 w-full text-gray-900 font-medium placeholder:text-gray-three pl-10 pr-10 block capitalize shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                v-model="form[data.payload]"
                :required="data.required"
              >
                <option
                  v-for="(option, index) in data.option"
                  :value="option.value"
                  :key="index"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center mt-10">
          <button
            :disabled="isLoading"
            :class="
              isLoading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-900'
            "
            type="submit"
            class="' w-64 relative flex items-center justify-center px-12 py-4 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 ',"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
} from '../browser-db-config/localStorage'

export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      disbursementOption: [],
      loanStatusOption: [],
      loanTypeOption: [],
      loanRepaymentOption: [],
      distribution_channel: [],
      loanData: [
        {
          name: 'Borrower Full Name',
          type: 'text',
          required: true,
          payload: 'borrower_name',
        },
        {
          name: 'Borrower Phone Number',
          type: 'number',
          required: true,
          payload: 'borrower_phone',
        },
        {
          name: ' Date of Birth',
          type: 'date',
          payload: 'dob',
        },
        {
          name: 'BVN',
          type: 'number',
          payload: 'bvn',
        },
        {
          name: 'Store ',
          type: 'select',
          payload: 'store_id',
          option: [],
        },
        {
          name: 'Loan Amount',
          type: 'number',
          required: true,
          payload: 'loan_amount',
        },
        {
          name: 'Loan Type',
          type: 'select',
          required: true,
          payload: 'loan_type_id',
          option: [],
        },
        {
          name: 'Loan Status',
          type: 'select',
          required: true,
          payload: 'loan_status_id',
          option: [],
        },
        {
          name: 'Interest Rate',
          type: 'text',
          payload: 'interest_rate',
        },
        {
          name: 'Tenor',
          type: 'select',
          payload: 'tenor',
          required: true,
          option: [
            {
              label: '1 Month',
              value: 1,
            },
            {
              label: '2 Months',
              value: 2,
            },
            {
              label: '3 Months',
              value: 3,
            },
            {
              label: '4 Months',
              value: 4,
            },
            {
              label: '5 Months',
              value: 5,
            },
            {
              label: '6 Months',
              value: 6,
            },
            {
              label: '7 Months',
              value: 7,
            },
            {
              label: '8 Months',
              value: 8,
            },
            {
              label: '9 Months',
              value: 9,
            },
          ],
        },
        {
          name: 'Repayment Method',
          type: 'select',
          required: true,
          payload: 'repayment_method_id',
          option: [],
        },
        {
          name: 'Disbursement Method',
          type: 'select',
          required: true,
          payload: 'disbursement_method_id',
          option: [],
        },
        {
          name: 'Distribution Channel',
          type: 'select',
          required: true,
          payload: 'distribution_channel',
          option: [],
        },
        {
          name: 'Referring Officer',
          type: 'email',
          payload: 'referring_officer',
        },
        {
          name: 'Is Business Registered',
          type: 'select',
          required: true,
          payload: 'is_business_registered',
          option: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
        },
      ],
      form: {
        loan_amount: '',
        loan_type_id: '',
        interest_rate: 0,
        tenor: 0,
        repayment_method_id: '',
        disbursement_method_id: '',
        referring_officer: '',
        loan_status_id: '',
        borrower_phone: '',
        borrower_name: '',
        store_id: '',
        distribution_channel: '',
        is_business_registered: false,
        dob: '',
        bvn: '',
      },
    }
  },
  methods: {
    async makeRequest() {
      const allDisbursement = async () => {
        return await this.$store.dispatch('GET_ALL_DISBURSEMENT_METHODS')
      }
      const allLoanStatus = async () => {
        return await this.$store.dispatch('GET_ALL_LOAN_STATUS')
      }
      const allLoanTypes = async () => {
        return await this.$store.dispatch('GET_ALL_LOAN_TYPES')
      }
      const allLoanRepaymentMethods = async () => {
        return await this.$store.dispatch('GET_ALL_LOAN_REPAYMENT_METHODS')
      }
      const allDistributionChannel = async () => {
        return await this.$store.dispatch('GET_ALL_DISTRIBUTION_CHANNELS')
      }

      return {
        allDisbursement: await allDisbursement(),
        allLoanStatus: await allLoanStatus(),
        allLoanTypes: await allLoanTypes(),
        allLoanRepaymentMethods: await allLoanRepaymentMethods(),
        allDistributionChannel: await allDistributionChannel(),
      }
    },
    async submit() {
      this.form.loan_amount = Number(this.form.loan_amount)
      this.form.interest_rate = Number(this.form.interest_rate)
      this.form.tenor = Number(this.form.tenor)
      this.isLoading = true
      const response = await this.$store.dispatch(
        'MAKE_LOAN_TRACKER',
        this.form
      )

      if (response.status === true) {
        this.$router.push({ name: 'ReportingPageView' })
      } else {
        prompt('Error', response.message)
      }

      this.isLoading = false
    },
  },
  async mounted() {
    this.loading = true
    const response = await this.makeRequest()
    this.disbursementOption = response.allDisbursement.data.item.map(
      ({ disbursement_method, _id }) => {
        return {
          label: disbursement_method,
          value: _id,
        }
      }
    )
    this.loanStatusOption = response.allLoanStatus.data.item.map(
      ({ status, _id }) => {
        return {
          label: status,
          value: _id,
        }
      }
    )
    this.loanTypeOption = response.allLoanTypes.data.item.map(
      ({ loan_type, _id }) => {
        return {
          label: loan_type,
          value: _id,
        }
      }
    )
    this.loanRepaymentOption = response.allLoanRepaymentMethods.data.item.map(
      ({ repayment_method, _id }) => {
        return {
          label: repayment_method,
          value: _id,
        }
      }
    )

    this.distributionChannelOption = response.allDistributionChannel.data.item.map(
      ({ distribution_channel, _id }) => {
        return {
          label: distribution_channel,
          value: distribution_channel,
        }
      }
    )

    const storeData = GET_USER_DATA()
      .stores.map(({ name, id }) => {
        return {
          label: name,
          value: id,
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label))

    this.loanData = this.loanData.map((data) => {
      if (data.type === 'select') {
        if (data.payload === 'disbursement_method_id') {
          data.option = this.disbursementOption
        }
        if (data.payload === 'loan_status_id') {
          data.option = this.loanStatusOption
        }
        if (data.payload === 'loan_type_id') {
          data.option = this.loanTypeOption
        }
        if (data.payload === 'repayment_method_id') {
          data.option = this.loanRepaymentOption
        }
        if (data.payload === 'store_id') {
          data.option = storeData
        }
        if (data.payload === 'distribution_channel') {
          data.option = this.distributionChannelOption
        }
      }
      return data
    })

    this.loading = false
  },
}
</script>
